import {json, type MetaFunction} from "@remix-run/node";
import {Link, useFetcher, useLoaderData} from "@remix-run/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import loaderFn from "./route.loader.server";
import actionFn, {SignInFailure} from "./route.action.server";
import {Turnstile} from "@marsidev/react-turnstile";

export const loader = loaderFn;
export const action = actionFn;
export const meta: MetaFunction = () => {
    return [
        {title: "ResultX - PaperTrail - Sign in"},
    ];
};

export default function AuthLogin() {
    const { cloudflareTurnstileSiteKey } = useLoaderData<typeof loader>();
    const fetcher = useFetcher<SignInFailure>();

    return (
        <>
            <h2 className="card-title mb-8 text-2xl font-semibold">
                Sign in
            </h2>
            {fetcher.data?.errors.message && (
                <div className="alert alert-error mb-2">
                    {fetcher.data.errors.message}
                </div>
            )}
            <fetcher.Form method="POST">
                <div className="mb-2">
                    <input type="text" name="email" placeholder="Email address"
                           defaultValue={fetcher.data?.form?.email}
                           className={clsx("input input-bordered w-full", fetcher.data?.errors.email && 'input-error')}/>
                    {fetcher.data?.errors.email && (
                        <div className="label">
                            <span className="label-text-alt text-error">{fetcher.data?.errors.email}</span>
                        </div>
                    )}
                </div>

                <div className="mb-2">
                    <input type="password" name="password" placeholder="Password"
                           className={clsx("input input-bordered w-full", fetcher.data?.errors.password && 'input-error')}/>
                    {fetcher.data?.errors.password && (
                        <div className="label">
                            <span className="label-text-alt text-error">{fetcher.data?.errors.password}</span>
                        </div>
                    )}
                </div>
                <Link to='/auth/reset-password' className="text-secondary hover:mix-blend-darken">Forgot password?</Link>

                <div className="mt-4 flex justify-center">
                    <Turnstile siteKey={cloudflareTurnstileSiteKey} options={{theme: 'light', appearance: 'interaction-only'}}/>
                </div>

                <div className="card-actions mt-4">
                    <button type="submit" className="btn btn-primary w-full" name="_action" value="signIn"
                            disabled={fetcher.state === 'submitting'}>
                        {fetcher.state === 'submitting' ? (
                            <FontAwesomeIcon icon={faSpinner} className="w-4 animate-spin"/>
                        ) : 'Sign in'}
                    </button>
                </div>
            </fetcher.Form>
        </>
    )
}
